import {
    BasicShippingTool,
    CartPaymentAndShipmentController,
    HomepageWidget,
    QuickShippingTool,
    ShippingToolProvider,
    ThankYouPage
} from '@shipitfi/shipping-tool';
import React from 'react';
import { createRoot } from 'react-dom/client';
import '../bootstrap';

const COMPONENT_MAP = {
    'homepage-widget-shipping-tool': HomepageWidget,
    'basic-shipping-tool': BasicShippingTool,
    'quick-shipping-tool': QuickShippingTool,
    'shipping-tool-cart': CartPaymentAndShipmentController,
    'shipping-tool-thank-you': ThankYouPage
};

// Store mounted components in a WeakSet to allow garbage collection
const mountedComponents = new WeakSet();

const parseProps = dataset =>
    Object.entries(dataset).reduce((acc, [key, value]) => {
        if (key === 'shippingToolInitData') {
            return { ...acc, ...JSON.parse(value) };
        }
        return {
            ...acc,
            [key.endsWith('Json') ? key.slice(0, -4) : key]:
            key.endsWith('Json') ? JSON.parse(value) : value
        };
    }, {});

const renderComponent = (element, props, Component) => {
    createRoot(element).render(
        <ShippingToolProvider
            language={props.languageCode}
            countryMeta={props.countryMeta}
            homePageUrl={props.homePageUrl || null}
            userSessionUuid={props.userSessionUuid || null}
            cartId={props.cartId || null}
            cartCloseApi={props.cartCloseApi || null}
        >
            <Component {...props} />
        </ShippingToolProvider>
    );
};

const mountComponent = element => {
    if (!element ||
        mountedComponents.has(element) ||
        !COMPONENT_MAP[element.id]) {
        return;
    }

    try {
        const props = parseProps(element.dataset);
        renderComponent(element, props, COMPONENT_MAP[element.id]);
        mountedComponents.add(element);
        console.log(`Successfully mounted component: ${element.id}`);
    } catch (error) {
        console.error(`Error mounting component ${element.id}:`, error);
    }
};

const handleMutations = mutations => {
    mutations.forEach(mutation => {
        // Handle attribute modifications
        if (mutation.type === 'attributes' &&
            mutation.attributeName === 'data-shipping-tool' &&
            mutation.target.hasAttribute('data-shipping-tool')) {
            mountComponent(mutation.target);
            return;
        }

        // Handle new nodes
        Array.from(mutation.addedNodes)
            .filter(node =>
                node.nodeType === 1 &&
                node.hasAttribute &&
                node.hasAttribute('data-shipping-tool')
            )
            .forEach(mountComponent);
    });
};

const createObserver = () => {
    const observer = new MutationObserver(handleMutations);
    observer.observe(document.documentElement, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['data-shipping-tool']
    });

    return observer;
};

const periodicCheck = (checkCount = 0, maxChecks = 10) => {
    if (checkCount >= maxChecks) {
        return;
    }

    const unmountedExists = Array.from(document.querySelectorAll('[data-shipping-tool]')).some(component => {
            if (!mountedComponents.has(component)) {
                mountComponent(component);

                return true;

            }

            return false;
        });

    if (unmountedExists) {
        setTimeout(() => periodicCheck(checkCount + 1, maxChecks), 100);
    }
};

const initialize = () => {
    // Mount initial components
    document.querySelectorAll('[data-shipping-tool]').forEach(mountComponent);

    const observer = createObserver();
    periodicCheck();

    return observer;
};

const cleanup = observer => {
    observer?.disconnect();
    // No need to clear mountedComponents as it's a WeakSet
};

const start = () => {
    let observer = initialize();

    // Handle navigation
    window.addEventListener('popstate', () => {
        cleanup(observer);

        observer = initialize();
    });

    // Cleanup function for module unload
    return () => cleanup(observer);
};

// Initialize on DOM ready
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', start);
} else {
    start();
}
